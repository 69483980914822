import React, { useState } from "react";

import { Button, Img, Input, Text } from "components";
import { useNavigate, useParams } from "react-router-dom";
import '../Signin/SignIn.css'
import * as config from 'config.js';
import { error } from "console";


const ForgotPasswordPage: React.FC = () => {

  let navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('')


const navigateToDashboard = () => {
    navigate("/dashboard")
    window.location.reload();
}

  const changePassword = async () => {
        try {
            const response = await fetch(config.BASE_URL + `/auth/change-password`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              },
              body: JSON.stringify({ newPassword, oldPassword }),
            });

            const data = await response.json()
            console.log(data.error)

            if (data.error) {
              alert(data.message)
            } else {
                alert("Password change success!")
                navigateToDashboard()
            }
                
    } catch (err) {
      alert(err.message);
    }
  }


  return (
    <>
      <div className="row h-100">
        <div className="col-6 bg-green-200 flex-col justify-center flex px-48">
          <Img
            className="h-[58px] md:ml-[0] ml-[5px] absolute top-24"
            src="/images/img_logo.svg"
            alt="logo"
          />
          <div className="mt-16">
            <div className="flex flex-col gap-[5px] items-start justify-start w-full">
              <Text
                className="sm:text-[25.27px] md:text-[27.27px] text-[29.27px] text-gray-900"
                size="txtIBMPlexSansBold2927"
              >
                Change Password{" "}
              </Text>
              <Text
                className="text-[17.56px] text-gray-900"
                size="txtIBMPlexSansRegular1756"
              >
                Change your password
              </Text>
            </div>
            <div className="flex flex-col items-start justify-start w-[99%] md:w-full mt-8">
              <div className="flex flex-col items-center justify-start pt-0.5 w-full">
                <div className="flex flex-col gap-[7px] items-start justify-start w-full ">
                  <Text
                    className="text-[11.71px] text-gray-900 tracking-[1.13px] uppercase "
                    size="txtIBMPlexSansMedium1171"
                  >
                    Current Password
                  </Text>
                  <Input
                    name="CurrentPassword"
                    placeholder="Current Password"
                    className="leading-[normal] p-0 placeholder:text-gray-900 text-[15px] text-left w-full "
                    wrapClassName="border border-dark border-solid w-full"
                    type="password"
                    shape="square"
                    color="white_A700_19"
                    size="xs"
                    variant="fill"
                    onChange={(e) => setOldPassword(e)}
                  ></Input>
                </div>
                <div className="flex flex-col gap-[7px] items-start justify-start w-full mt-4">
                  <Text
                    className="text-[11.71px] text-gray-900 tracking-[1.13px] uppercase "
                    size="txtIBMPlexSansMedium1171"
                  >
                    New Password
                  </Text>
                  <Input
                    name="NewPassword"
                    placeholder="New Password"
                    className="leading-[normal] p-0 placeholder:text-gray-900 text-[15px] text-left w-full "
                    wrapClassName="border border-dark border-solid w-full"
                    type="password"
                    shape="square"
                    color="white_A700_19"
                    size="xs"
                    variant="fill"
                    onChange={(e) => setNewPassword(e)}
                  ></Input>
                </div>
              </div>
              <div onClick={() => changePassword()} className="flex flex-col items-center justify-start mt-[25px] pb-[9px] w-full">
                <div className="w-100 btn btn-lg signInBtnBackground">
                  <Button className="text-green-200 text-[17.56px]">Change Password</Button>
                </div>
              </div>
              <div className="flex gap-1 justify-center w-full">
                <div className="flex flex-col items-start justify-start mt-4">
                  <Button className="underline" onClick={() => navigateToDashboard() }>Back to dashboard</Button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-6 imgStyle flex justify-center">
          <img
            src="images/img_image.svg"
            alt="image"
          />
        </div>
      </div>

    </>
  );
};

export default ForgotPasswordPage;
