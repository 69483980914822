import React from 'react';
import PropTypes from 'prop-types';
import { Img, Input, TopBar, Text } from "components";

const CustomInputComponent = ({ placeholder, onChange, label, value = "", type = "text" }) => {
  return (
    <div className="relative">
      <Text
        className="text-gray-800_87 text-sm mb-2"
        size="txtIBMPlexSansRegular14Gray80087"
      >
        {label}
      </Text>
      
      <Img
        className="h-6 ml-auto mr-5 my-auto w-6 absolute top-1/2 right-1 transform"
        src="images/img_edit.svg"
        alt="edit"
      />
      <Input
        placeholder={placeholder}
        value={value}
        className="justify-center leading-[normal] p-0 placeholder:text-gray-400 text-base text-left w-full"
        wrapClassName="inset-[0] m-auto w-full"
        shape="square"
        color="gray_800_64"
        type={type}
        onChange={onChange}
        size="md"
        variant="outline"
      />
    </div>
  );
};

CustomInputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomInputComponent;