import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import { MainLayout } from "MainLayout";
import LoadingSpinner from "components/LoadingSpinner/LoadingComponent";
import { Parse } from "pages/Parse";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import ChangePassword from "pages/ChangePassword";
const SubscriptionCancelledModal = React.lazy(
  () => import("pages/SubscriptionCancelledModal"),
);
const Settings = React.lazy(() => import("pages/Settings"));
const Invoices = React.lazy(() => import("pages/Invoices"));
const TenantDetails = React.lazy(() => import("pages/TenantDetails"));
const Tenants = React.lazy(() => import("pages/Tenants"));
const ErrorPopup = React.lazy(() => import("pages/ErrorPopup"));
const SuccessPopup = React.lazy(() => import("pages/SuccessPopup"));
const TenantAddedSuccessPopup = React.lazy(
  () => import("pages/TenantAddedSuccessPopup"),
);
const PropertyDetails = React.lazy(() => import("pages/PropertyDetails"));
const Properties = React.lazy(() => import("pages/Properties"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const ForgotPassword = React.lazy(() => import("pages/ForgotPassword"));
const SignUp = React.lazy(() => import("pages/SignUp"));
const Signin = React.lazy(() => import("pages/Signin"));
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<LoadingSpinner/>}>
      <Router>
  <MainLayout>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/property/" element={<PropertyDetails />} />
          <Route path="/forgotpassword/:userId/:token" element={<ResetPassword />} />
          <Route
            path="/tenantaddedsuccesspopup"
            element={<TenantAddedSuccessPopup />}
          />
          <Route path="/successpopup" element={<SuccessPopup />} />
          <Route path="/errorpopup" element={<ErrorPopup />} />
          <Route path="/tenants" element={<Tenants />} />
          <Route path="/tenant/" element={<TenantDetails />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/parse" element={<Parse />} />
          <Route
            path="/subscriptioncancelledmodal"
            element={<SubscriptionCancelledModal />}
          />
        </Routes>
  </MainLayout>




      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
