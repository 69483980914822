import {
    Button,
    Img,
    Input,
  } from "components";
  
  import { CloseSVG } from "../../assets/images";
  
import React from "react"

function navigateToSettings() {
  window.location.pathname = "/settings"
}

function TopBar({action = (() => {}) }) {
  
  function navigateToAddNewProperty() {
    //
    action()
  }

return ( 
<>
<div className="row w-100 mt-4 ">
<div className="w-100 flex search-bar justify-end px-0">
  <div className="right-side flex w-100 justify-end gap-4">
    <Button
    onClick={navigateToAddNewProperty}
      className="cursor-pointer font-bold min-w-[195px] md:ml-[0] ml-[534px] text-base text-center"
      shape="square"
      color="teal_200"
      size="md"
      variant="fill"
    >
      Add New Property
    </Button>
   {/* <div className="flex items-center ml-3 ">
   <Img
      src="images/img_music.svg"
      alt="music"
    />
   </div> */}
    <Button
    onClick={navigateToSettings}
      className="flex h-[45px] items-center justify-center mb-[3px] md:ml-[0] rounded-[22px] w-[45px]"
      color="blue_gray_100"
      size="sm"
      variant="outline"
    >
      <Img
        className="h-6"
        src="images/img_iconuser.svg"
        alt="iconuser"
      />
    </Button>
  </div>
</div>
</div>
</>
);
};

export {TopBar};