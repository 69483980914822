import { TopBar } from 'components';
import Sidebar1 from 'components/Sidebar1';
import AddNewPropertyModalModal from 'modals/AddNewPropertyModal';
import * as config from "config.js";
import React, { useEffect, useState } from 'react';

const MainLayout = ({ children }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    let pathName = window.location.pathname

    const canAddProperty = async () => {
        try {
            const response = await fetch(config.BASE_URL + `/stripe/active-subscription`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                },
              });
            if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            }
            
            let actualData = await response.json();

            if (actualData.data.propertiesNumber > 0)
                return true
            else
                return false

        } catch (err) {
            return false
        }
    };

    async function openModal() {
        let canAddPropertyValue = await canAddProperty()

        if (canAddPropertyValue)
            setIsModalOpen(true)
        else {
            alert("Please update your subscription!")
            window.location.replace("/settings")
        }
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    const pathToVerify = ["/signin", "/signup", "/forgotpassword", "change-password"]

    if (pathToVerify.some(string => pathName.includes(string)) || pathName === "/")
        return (
            <div className='container-fluid h-100'>

                {children}
            </div>
        );
    else
        return (
            <div className='container-fluid h-100'>

                <AddNewPropertyModalModal isOpen={isModalOpen} closeModal={closeModal} />
                <div className='row'>
                    {/* Sidebar */}
                    <aside className="col-2">
                        <Sidebar1 />
                    </aside>

                    {/* Main Content */}
                    <main className="col-10 px-4">
                        {/* Top bar */}
                        <TopBar action={openModal} />
                        {children}
                    </main>
                </div>
                {/* Footer -- to be added
            <footer>
                <p>&copy; 2023 Your Company</p>
            </footer> */}

            </div>
        );


};
export { MainLayout }