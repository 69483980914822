import CustomInputComponent from "components/CustomInput"
import React, { useState } from "react"
import { Img, Input, TopBar, Text, Button } from "components";
import * as config from "config.js";
import { callbackify } from "util";

function Parse() {

    const [invoiceUrl, setInvoiceUrl] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { name: 'Digi', value: 'Digi' },
        { name: 'Electrica Furnizare', value: 'Electrica Furnizare' },
        { name: 'Asociatie', value: 'Asociatie' },
        { name: 'Enel', value: 'Enel' },
        { name: 'Distrigaz Vest', value: 'Distrigaz Vest' },
    ];


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const callBackend = async (object) => {
        try {
          const response = await fetch(config.BASE_URL + "/parser/parse-invoice", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
          });
    
          if (!response.ok) {
          }
    
          const data = await response.json();
    
          if (data.type == "error") 
            alert(data.message);
          else
            alert("Invoice added, it will take some time to parse it, verify invoices page in a few moments.")

          console.log("Success!", data);
    

        } catch (err) {
          console.error(err.message);
        }
      };

const handleNext = () => {
    let object = {
        "invoiceUrl": invoiceUrl,
        "issuer": selectedOption
      }

      callBackend(object)
}

    return (
        <>
            <div className="row w-50">




                <div>
                    <Text
                        className="text-gray-800_87 text-sm mb-2"
                        size="txtIBMPlexSansRegular14Gray80087"
                    >
                        Select supplier
                    </Text>
                    <select className="w-100" value={selectedOption} onChange={handleSelectChange}>
                        <option value="" selected disabled>Select an option</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>{option.name}</option>
                        ))}
                    </select>
                </div>

                <div className="mt-4">
                    <CustomInputComponent label="Invoice Url" placeholder="https://anything.com/file.pdf" onChange={setInvoiceUrl} />
                </div>

                <div onClick={handleNext} className="flex w-full mt-8">
              <Button
                className="cursor-pointer leading-[normal] min-w-[253px] text-base text-center"
                shape="square"
                color="gray_900"
                size="md"
                variant="fill"
              >
                Add Invoice
              </Button>
            </div>

            </div>
        </>
    )
}

export { Parse }