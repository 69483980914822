import React from "react";

const sizeClasses = {
  txtIBMPlexSansRegular16Gray900: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular15Gray800: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold16WhiteA700: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular14WhiteA700a2: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular12Bluegray40090: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular14Gray800: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular14Gray80087: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold24Gray800: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular15OrangeA700: "font-ibmplexsans font-normal",
  txtIBMPlexSansMedium1171: "font-ibmplexsans font-medium",
  txtIBMPlexSansBold2927: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular16Red500: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold1756: "font-bold font-ibmplexsans",
  txtIBMPlexSansBold24: "font-bold font-ibmplexsans",
  txtIBMPlexSansBold25: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular20: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular1756: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold16: "font-bold font-ibmplexsans",
  txtIBMPlexSansMedium15: "font-ibmplexsans font-medium",
  txtIBMPlexSansBold18: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular14Bluegray400: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular16Teal200: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular16WhiteA700: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular16: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular15: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular14: "font-ibmplexsans font-normal",
  txtIBMPlexSansRegular13: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold1756GreenA100: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular12: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold15: "font-bold font-ibmplexsans",
  txtIBMPlexSansRegular32: "font-ibmplexsans font-normal",
  txtIBMPlexSansBold14: "font-bold font-ibmplexsans",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: any;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
