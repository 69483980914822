import React, { useState } from "react";
import { default as ModalProvider } from "react-modal";

import { Button, Img, Input, SelectBox, Text } from "components";
import * as config from 'config.js';
import { useNavigate } from "react-router-dom";

const AddNewPropertyModalModal = ({ isOpen, closeModal }) => {
  const [fullAddress, setFullAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [propertyTypeToUse, setPropertyTypeToUse] = useState("")

  const propertyTypes = [
    {
      label: "Apartament",
      value: "1"
    },
    {
      label: "Casa",
      value: "2"
    },
    {
      label: "Spatiu Comercial",
      value: "3"
    },
    {
      label: "Parcare",
      value: "4"
    }
  ]
 
  const navigate = useNavigate()

  const callBackend = async (object) =>  {

    try {
      const response = await fetch(config.BASE_URL + '/properties/add', {
       method: 'POST',
       headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
       },
  
       body: JSON.stringify(object),
      });

     
  
      const data = await response.json();

      if(data.type == "error") {
        alert(data.message)
      }

      console.log('Success!', data);

      navigate('/property', {state: data.data.id});
      closeModal()
    } catch (err) {
      console.error(err.message);
    }
  }


  const addNewProperty = () => {
    const object = {
      "fullAddress": fullAddress,
      "city": city,
      "country": country,
      "occupied": false,
      "propertyType": propertyTypeToUse
    }

    callBackend(object)

    console.log(" button pressed", object);
  };



  return (
    <ModalProvider
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById("root")}
      className="m-auto !w-[42%]"
      overlayClassName="bg-gray-800_66 fixed flex h-full inset-y-[0] w-full"
    >
      <div className="max-h-[97vh] overflow-y-auto sm:w-full md:w-full">
        <div className="bg-white-A700 flex flex-col gap-10 justify-end p-[27px] md:px-5 w-full relative">
          <Img
            onClick={closeModal}
            className="h-6 mb-0.5 w-6 cursor-pointer absolute top-16 right-8"
            src="images/img_bxbxplus.svg"
            alt="bxbxplus"
          />
          <div className="flex items-start justify-center mt-8">
            <Text
              className="sm:mt-0 mt-0.5 text-center text-gray-800 text-lg"
              size="txtIBMPlexSansBold18"
            >
              Add New Property
            </Text>
          </div>
          <div className="flex flex-col gap-[37px] items-center justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col gap-[17px] items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-col gap-2.5 justify-start w-full">
                    <Text
                      className="md:ml-[0]  text-gray-800_87 text-sm"
                      size="txtIBMPlexSansRegular14Gray80087"
                    >
                      Full Address
                    </Text>
                    <Input
                      onChange={setFullAddress}
                      name="groupTen"
                      placeholder="Apartment"
                      className="p-0 placeholder:text-gray-400 text-left text-sm w-full"
                      wrapClassName="w-full"
                      shape="square"
                      color="gray_800_64"
                      size="md"
                      variant="outline"
                    ></Input>
                  </div>
                </div>
                <div className="flex sm:flex-col flex-row sm:gap-10 items-center justify-between w-full">
                  <div className="flex sm:flex-1 flex-col gap-[9px] justify-start w-[47%] sm:w-full">
                    <Text
                      className="md:ml-[0]  text-gray-800_87 text-sm"
                      size="txtIBMPlexSansRegular14Gray80087"
                    >
                      City
                    </Text>
                    <Input
                      onChange={setCity}
                      name="group469"
                      placeholder="Oradea"
                      className="leading-[normal] p-0 placeholder:text-gray-400 text-base text-left w-full"
                      wrapClassName="w-full"
                      shape="square"
                      color="gray_800_64"
                      size="md"
                      variant="outline"
                    ></Input>
                  </div>
                  <div className="flex sm:flex-1 flex-col gap-[9px] justify-start w-[47%] sm:w-full">
                    <Text
                      className="md:ml-[0]  text-gray-800_87 text-sm"
                      size="txtIBMPlexSansRegular14Gray80087"
                    >
                      Country
                    </Text>
                    <Input
                      onChange={setCountry}
                      name="country"
                      placeholder="Romania"
                      className="leading-[normal] p-0 placeholder:text-gray-400 text-base text-left w-full"
                      wrapClassName="w-full"
                      shape="square"
                      color="gray_800_64"
                      size="md"
                      variant="outline"
                    ></Input>
                  </div>
                  
                </div>

                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-col gap-2.5 justify-start w-full">
                  <Text
                      className="md:ml-[0]  text-gray-800_87 text-sm"
                      size="txtIBMPlexSansRegular14Gray80087"
                    >
                      Property type
                    </Text>

                  <SelectBox
                onChange={setPropertyTypeToUse}
                  className="!text-gray-800 leading-[normal] text-base text-left w-full"
                  placeholderClassName="!text-gray-800"
                  indicator={
                    <Img
                      className="h-6 mr-[0] w-6"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  isMulti={false}
                  name="groupFiftySix"
                  options={propertyTypes}
                  isSearchable={false}
                  placeholder="Select value"
                  shape="square"
                  color="gray_800_64"
                  size="sm"
                  variant="outline"
                />
                  </div>
                </div>
    
      

              </div>
            </div>
            <div onClick={addNewProperty} className="flex w-full">
              <Button
                className="cursor-pointer text-base text-center w-full"
                shape="square"
                color="gray_900"
                size="md"
                variant="fill"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default AddNewPropertyModalModal;
