import React from "react";

import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";

import { Img } from "components";

type Sidebar1Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{}>;
type SideBarMenuType = {
  icon?: JSX.Element;
  label: JSX.Element | string;
  suffix?: JSX.Element;
  href?: string;
  target?: string;
  active?: boolean;
}[];

const Sidebar1: React.FC<Sidebar1Props> = (props) => {

  const sideBarMenu: SideBarMenuType = [
    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_bxbxbarchartalt2.svg"
          alt="bxbxbarchartalt"
        />
      ),
      label: "Dashboard",
      href: "/dashboard",
      active: window.location.pathname === "/dashboard",
    },
    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_iconlisting.svg"
          alt="iconlisting"
        />
      ),
      label: "Properties",
      href: "/properties",
      active: window.location.pathname === "/properties",
    },
    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_iconusers.svg"
          alt="iconusers"
        />
      ),
      label: "Tenants",
      href: "/tenants",
      active: window.location.pathname === "/tenants",
    },
    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_iconmoney.svg"
          alt="iconmoney"
        />
      ),
      label: "Invoices",
      href: "/invoices",
      active: window.location.pathname === "/invoices",
    },
    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_settings.svg"
          alt="settings"
        />
      ),
      label: "Settings",
      href: "/settings",
      active: window.location.pathname === "/settings",
    },

    {
      icon: (
        <Img
          className="h-7 w-7"
          src="images/img_settings.svg"
          alt="parser"
        />
      ),
      label: "AI Parser",
      href: "/parse",
      active: window.location.pathname === "/parse",
    },
  ];

  return (
    <>
        <Sidebar className="shadow-bs w-100 h-100">
            <Img
              className="h-9 ml-8 md:ml-[0] mr-[73px] mt-[33px]"
              src="images/img_logo_black_900.svg"
              alt="logo"
            />
            <Menu
              menuItemStyles={{
                button: {
                  padding: "12px 12px 12px 17px",
                  gap: "20px",
                  marginTop: "12px",
                  color: "#3d3e42",
                  fontWeight: 600,
                  fontSize: "16px",
                  [`&:hover, &.ps-active`]: {
                    backgroundColor: "#1b1a2c25 !important",
                  },
                },
              }}
              className="flex flex-col items-center justify-start mb-[605px] mt-[77px] pt-[5px] px-4 w-[89%]"
            >
              {sideBarMenu?.map((menu, i) => (
                <MenuItem key={`sideBarMenuItem${i}`} {...menu}>
                  {menu.label}
                </MenuItem>
              ))}
            </Menu>
          </Sidebar>
          
    </>
  );
};

Sidebar1.defaultProps = {};

export default Sidebar1;
