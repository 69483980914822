import { ProSidebarProvider } from "react-pro-sidebar";
import React from "react";
import Routes from "./Routes";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import AddNewPropertyModalModal from "modals/AddNewPropertyModal";
import { TopBar } from "components";

function App() {
  return (
    <ProSidebarProvider>
         
      <Routes />

    </ProSidebarProvider>
  );
}

export default App;
