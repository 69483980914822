import React from "react";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded-lg",
  square: "rounded-none",
} as const;
const variants = {
  fill: {
    amber_A700: "bg-amber-A700",
    red_500: "bg-red-500",
    blue_gray_800_3a: "bg-blue_gray-800_3a",
    green_200: "bg-green-200",
    teal_200: "bg-teal-200 text-gray-900",
    gray_100_01: "bg-gray-100_01",
    amber_A700_19: "bg-amber-A700_19 text-amber-A700",
    red_500_19: "bg-red-500_19 text-red-500",
    gray_900: "bg-gray-900 text-white-A700",
    teal_200_19: "bg-teal-200_19 text-teal-200",
  },
  outline: {
    gray_900: "border border-gray-900 border-solid text-gray-900",
    red_500: "outline outline-[0.5px] outline-red-500 text-red-500",
    blue_gray_100: "outline outline-[1px] outline-blue_gray-100",
    green_200: "outline outline-[0.5px] outline-green-200 text-gray-800",
    teal_200: "outline outline-[0.5px] outline-teal-200 text-gray-800",
  },
} as const;
const sizes = {
  xs: "p-[5px]",
  sm: "p-2.5",
  md: "p-[13px]",
  lg: "p-[17px]",
} as const;

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onClick"
> &
  Partial<{
    className: string;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
  }>;

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
