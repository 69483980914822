import { Button } from "components/Button";
import React, { useMemo } from "react";
import { useTable } from "react-table";

function CustomTable({ columns, data, action = ((data) => {}) }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

    const handleClick = (data) => {
      action(data);
    };

    const propertyTypes = [
      {
        label: "Apartament",
        value: "1"
      },
      {
        label: "Casa",
        value: "2"
      },
      {
        label: "Spatiu Comercial",
        value: "3"
      },
      {
        label: "Parcare",
        value: "4"
      }
    ]
  
    const getPropertyTypeName = (id) => {
      return propertyTypes.find(object => object.value == id).label
    }
  

  return (
    <table
      {...getTableProps()}
      style={{ borderCollapse: "collapse", width: "100%" }}
    >
      <thead className="dashboard-header">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="text-gray-800_87 text-base font-medium"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
            <th className="text-gray-800_87 text-base font-medium">Action</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          
          prepareRow(row);
          const occupied = row.values.occupied;
          const city = row.values.city;
          const invoicesPayed = row.values.invoicesPayed;
          const paid = row.original.paid
          const propertyType = row.original.propertyType
          
      
          return (
            <tr
              className="border-b border-gray-800_6c w-100"
              key={row.id}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) =>
                cell.column.id === "occupied" || cell.column.id === "invoicesPayed" || cell.column.id === "markAs" || cell.column.id === "propertyType" ?(
                  <td{...cell.getCellProps()}
                  >
                    {cell.column.id === "occupied"
                      ? occupied
                        ?
                        <Button
                          className="!text-green-200 cursor-pointer leading-[normal] min-w-[72px]  mt-[13px] text-[15px] text-center"
                          shape="round"
                          color="teal_200_19"
                          size="sm"
                          variant="fill"
                        >
                          Occupied
                        </Button>
                        :
                        <Button
                          className="!text-red-400 cursor-pointer leading-[normal] min-w-[72px]  mt-[13px] text-[15px] text-center"
                          shape="round"
                          color="red_500_19"
                          size="sm"
                          variant="fill"
                        >
                          Not Occupied
                        </Button>
                      : cell.column.id === "invoicesPayed" ? (invoicesPayed ? <Button
                        className="!text-green-200 cursor-pointer leading-[normal] min-w-[72px]  mt-[13px] text-[15px] text-center"
                        shape="round"
                        color="teal_200_19"
                        size="sm"
                        variant="fill"
                      >
                        Paid
                      </Button> : <Button
                        className="!text-red-400 cursor-pointer leading-[normal] min-w-[72px]  mt-[13px] text-[15px] text-center"
                        shape="round"
                        color="red_500_19"
                        size="sm"
                        variant="fill"
                      >
                        Not paid
                      </Button>)
                        : cell.column.id === "markAs" ? <div>
                          {paid ?                          
                           <select className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="paymentStatus"> 
                            <option selected value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                          </select> :                           
                          <select className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="paymentStatus"> 
                            <option value="paid">Paid</option>
                            <option selected value="unpaid">Unpaid</option>
                          </select>
                          }


                        </div>
                        : cell.column.id === "propertyType" ? <div>
                          { propertyType ? getPropertyTypeName(propertyType) : "Not defined" }
                        </div>
                          : cell.render("Cell")

                    }
                  </td>
                ) : (
                  <td
                    className="pb-3 pt-[26px] text-base text-gray-800"
                    {...cell.getCellProps()}
                  >
                    {(cell.column.id === "city" ? <div className="mr-8">
                   { (city === "" ? "Empty" : city) }
                    </div> : cell.render("Cell"))}
                  </td>


                )
              )}
              <td>
                {" "}
                <Button
                  className="cursor-pointer leading-[normal] min-w-[67px] mt-[9px] text-[15px] text-center"
                  shape="square"
                  color="green_200"
                  size="xs"
                  onClick={() => handleClick(row.original.id)}
                  variant="outline"
                >
                  View
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { CustomTable };
